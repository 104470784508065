.toast-container {
    display: flex;
    align-items: center;
    z-index: 99999, 
  }
  
  .toast-container.bottom {
  flex-direction: column-reverse;
  }
  .toast-container.top {
  flex-direction: column;
  }
  
  .toast {
    width: fit-content;
    animation-fill-mode: forwards;
    --animation-duration: 3s;
  }
  
  /* Positioning */
  .top-right {
    position: fixed;
    top: 50px;
    right: 60px;
  }
  
  .top-left {
    position: fixed;
    top: 50px;
    left: 60px;
  }
  
  .top-center {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .bottom-right {
    position: fixed;
    bottom: 20px;
    right: 60px;
  }
  
  .bottom-left {
    position: fixed;
    bottom: 20px;
    left: 60px;
  }
  
  .bottom-center {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Fade animation */
  .fadeIn {
    animation: fadeIn 0.5s ease-out, fadeOut 0.5s ease-out;
    animation-delay: 0s, calc(var(--animation-duration) - 0.3s);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  
  /* Pop animation */
  .popIn {
    animation: popIn 0.5s ease-out, popOut 0.5s ease-out;
    animation-delay: 0s, calc(var(--animation-duration) - 0.3s);
  }
  
  @keyframes popIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes popOut {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.9);
    }
  }
  
  /* Slide animation */
  .slideIn {
    animation: slideIn 0.5s ease-out, slideOut 0.5s ease-out;
    animation-delay: 0s, calc(var(--animation-duration) - 0.3s);
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(10px);
    }
  }